import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Box,
  Paper,
  useMediaQuery,
  CssBaseline,
  ThemeProvider,
  createTheme,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { Send } from '@mui/icons-material';
import { motion } from 'framer-motion';

import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate and useLocation

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212',
      paper: '#1d1d1d',
    },
    text: {
      primary: '#ffffff',
    },
  },
});

// Define a function to check if the user is authenticated
const isAuthenticated = () => {
  return sessionStorage.getItem('isLoggedIn') === 'true';
};

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [roomNumber, setRoomNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [bookingType, setBookingType] = useState('checkIn'); // default value is 'checkIn'
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10)); // Initialize with today's date

  const navigate = useNavigate();
  const location = useLocation();

  // Check if the user is authenticated
  useEffect(() => {
    if (!isAuthenticated()) {
      // If the user is not authenticated, redirect them to the login page
      navigate('/',  );
    }
  }, [navigate, location]);

  const sendMessage = async () => {
    if (name && roomNumber && phoneNumber && price && date) {
      setLoading(true);
      const now = new Date();
      const currentTime = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;

      const message = `
        New data received:\n
        Name: ${name}\n
        Room Number: ${roomNumber}\n
        Phone Number: ${phoneNumber}\n
        Price: ${price}\n
        Description: ${description}\n
        Visiting Type: ${bookingType === 'checkIn' ? 'Check-in' : 'Check-out'}\n
        Date: ${date}\n
        Time: ${currentTime}
      `;

      try {
        const response = await axios.post(`https://api.telegram.org/bot7432720635:AAFXXzPBLjb79T3PHyF1s7_vGIqaHCCB-aM/sendMessage`, {
          chat_id: "-1002190056795",
          text: message,
        });

        console.log("Message sent successfully:", response.data);

        // Clear the fields after successful submission
        setName('');
        setRoomNumber('');
        setPhoneNumber('');
        setPrice('');
        setDescription('');
        setDate(new Date().toISOString().slice(0, 10)); // Reset date to today
        setErrorMessage('');
      } catch (error) {
        console.error("Error sending message:", error);
        setErrorMessage("Failed to send message. Please try again.");
      } finally {
        setLoading(false);
      }
    } else {
      setErrorMessage("All fields are required.");
    }
  };

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          backgroundColor: theme.palette.background.default,
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: isSmallScreen ? '20px' : '40px',
        }}
      >
        <Container maxWidth="sm">
          <Box
            component={motion.div}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            bgcolor={theme.palette.background.paper}
            color={theme.palette.text.primary}
            p={2}
            boxShadow={3}
            borderRadius={8}
            m={2}
            sx={{ width: '100%' }}
          >
            <Paper elevation={0} sx={{ padding: '20px', backgroundColor: theme.palette.background.paper, borderRadius: '10px', boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)' }}>
              <Typography variant="h3" gutterBottom component={motion.div} initial={{ scale: 0.9 }} animate={{ scale: 1 }} transition={{ type: 'spring', stiffness: 100, duration: 0.5 }} style={{ fontFamily: 'Montserrat, sans-serif', textAlign: 'center' }}>
                Helin De Ville
              </Typography>
              <Box sx={{ width: '100%' }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Visitor Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  InputProps={{ style: { color: 'white' } }}
                  InputLabelProps={{ style: { color: 'white' } }}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Room Number"
                  value={roomNumber}
                  onChange={(e) => setRoomNumber(e.target.value)}
                  required
                  InputProps={{ style: { color: 'white' } }}
                  InputLabelProps={{ style: { color: 'white' } }}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                  InputProps={{ style: { color: 'white' } }}
                  InputLabelProps={{ style: { color: 'white' } }}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  required
                  InputProps={{ style: { color: 'white' } }}
                  InputLabelProps={{ style: { color: 'white' } }}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Description *"
                  multiline
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  InputProps={{ style: { color: 'white' } }}
                  InputLabelProps={{ style: { color: 'white' } }}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Date *"
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  InputProps={{ style: { color: 'white' } }}
                  InputLabelProps={{ style: { color: 'white' } }}
                />
                <RadioGroup
                  aria-label="booking-type"
                  name="booking-type"
                  value={bookingType}
                  onChange={(e) => setBookingType(e.target.value)}
                  row
                  sx={{ marginTop: '10px' }}
                >
                  <FormControlLabel value="checkIn" control={<Radio />} label="Check-in" />
                  <FormControlLabel value="checkOut" control={<Radio />} label="Check-out" />
                </RadioGroup>
                {errorMessage && (
                  <Typography color="error" variant="body2" sx={{ marginTop: '10px' }}>
                    {errorMessage}
                  </Typography>
                )}
                <Box mt={2}>
                  <Button
                    onClick={sendMessage}
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    startIcon={<Send />}
                    sx={{ backgroundColor: '#6200ea', color: 'white' }}
                    component={motion.div}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    {loading ? <CircularProgress size={24} /> : 'Send'}
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Home;
