import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Home from './Home';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter, Routes, Route } from 'react-router-dom'; // Import Routes

// Create a dark theme using Material UI's createTheme
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212',
      paper: '#1d1d1d',
    },
    text: {
      primary: '#ffffff',
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    <BrowserRouter>
      <Routes> {/* Wrap your routes inside <Routes> */}
        <Route path='/' element={<App />} />
        <Route path='/home' element={<Home />} /> {/* Use '/home' instead of '/Home' */}
      </Routes>
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById('root')
);
