import React from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { motion } from 'framer-motion';

const Footer = () => (
  <Box mt={4} textAlign="center" sx={{ position: 'absolute', bottom: '20px', width: '100%' }}>
    <Grid container justifyContent="center" alignItems="center">
      <IconButton
        onClick={() => window.open("https://mega-soft.tech", "_blank")}
        color="inherit"
        component={motion.div}
        whileHover={{ scale: 1.2 }}
      >
        <img src="https://svgur.com/i/16q2.svg" alt="website" style={{ width: '30px', height: '30px' }} />
      </IconButton>
      <Typography variant="body2" sx={{ fontFamily: 'Roboto, sans-serif' }}>
        Powered By Mega Soft
      </Typography>
    </Grid>
  </Box>
);

export default Footer;
