import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Box,
  Paper,
  useMediaQuery,
  CssBaseline,
  ThemeProvider,
  createTheme,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { Send } from '@mui/icons-material';
import { motion } from 'framer-motion';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212',
      paper: '#1d1d1d',
    },
    text: {
      primary: '#ffffff',
    },
  },
});

const App = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate(); // Initialize navigate hook

  const handleLogin = () => {
    // Simulated login logic
    if (username === 'admin' && password === '12345678') {
      // Save login status to session
      sessionStorage.setItem('isLoggedIn', 'true');

      // Redirect to Home route upon successful login
      navigate('/home');
    } else {
      setLoginError('Invalid username or password');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          backgroundColor: theme.palette.background.default,
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '40px',
        }}
      >
        <Container maxWidth="sm">
          <Box
            component={motion.div}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            bgcolor={theme.palette.background.paper}
            color={theme.palette.text.primary}
            p={2}
            boxShadow={3}
            borderRadius={8}
            m={2}
            sx={{ width: '100%' }}
          >
            <Paper elevation={0} sx={{ padding: '20px', backgroundColor: theme.palette.background.paper, borderRadius: '10px', boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)' }}>
              <Typography variant="h3" gutterBottom component={motion.div} initial={{ scale: 0.9 }} animate={{ scale: 1 }} transition={{ type: 'spring', stiffness: 100, duration: 0.5 }} style={{ fontFamily: 'Montserrat, sans-serif', textAlign: 'center' }}>
                Login
              </Typography>
              <Box sx={{ width: '100%' }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                  InputProps={{ style: { color: 'white' } }}
                  InputLabelProps={{ style: { color: 'white' } }}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  InputProps={{ style: { color: 'white' } }}
                  InputLabelProps={{ style: { color: 'white' } }}
                />
                {loginError && (
                  <Typography color="error" variant="body2" sx={{ marginTop: '10px' }}>
                    {loginError}
                  </Typography>
                )}
                <Box mt={2}>
                  <Button
                    onClick={handleLogin}
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={<Send />}
                    sx={{ backgroundColor: '#6200ea', color: 'white' }}
                    component={motion.div}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Login
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Container>
      </Box>
      <Footer />
    </ThemeProvider>
  );
};

export default App;
